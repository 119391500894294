.share-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // hover colors 10% lighter

  &__icon {
    flex-shrink: 0;
    min-width: 0;
    margin: 10px;
    overflow: visible;

    &_type {
      &_facebook {
        color: #2f80ed;

        &:hover {
          color: #499aff;
        }
      }

      &_messenger {
        color: #2d9cdb;

        &:hover {
          color: #47b6f5;
        }
      }

      &_twitter {
        color: #56ccf2;

        &:hover {
          color: #70e6ff;
        }
      }

      &_mail {
        color: #eb5757;

        &:hover {
          color: #ff7171;
        }
      }

      &_web-share {
        color: #8a8a8a;

        &:hover {
          color: #a4a4a4;
        }
      }

      &_whatsapp {
        color: #23d465;

        &:hover {
          color: #33dd73;
        }
      }
    }
  }

  &__heading-check {
    margin-right: 5px;
    color: var(--c-main-color);
    vertical-align: middle;
  }

  &__progress {
    position: relative;
    height: 100px;
  }
}

.menu__section_type_share-actions * {
  margin-bottom: 10px;

  &:not(:first-child) {
    margin-top: 10px;
  }
}
