$main-color: #00b025;
$accent-color: #fb8f2d;

$text-color: #333;
$secondary-color: #8a8a8a;
$disabled-color: #d6d6d6;

$google-color: #db4437;
$facebook-color: #4267b2;

:root {
  --c-main-color: #00b025;
  --c-main-hover-color: #{desaturate($main-color, 10)};
  --c-main-a10-color: #e6f7e9; // #{rgba($main-color, 0.1)};
  --c-accent-color: #fb8f2d;
  --c-accent-hover-color: #f8a051;
  --c-accent-a10-color: #fff4ea; // #{rgba($accent-color, 0.1)};
  --c-text-color: #333;
  --c-text-a10-color: #ebebeb; // #{rgba($text-color, 0.1)};
  --c-secondary-color: #8a8a8a;
  --c-secondary-hover-color: #a4a4a4; /* 10% lighter */
  --c-disabled-color: #d6d6d6;
  --c-background-color: #fff;
  --c-error-color: #eb5757;
  --c-success-color: #{$main-color};

  // Additional
  --c-spinner-color: var(--c-main-color);

  // social auth colors
  --c-google-auth-color: #{$google-color};
  --c-google-auth-hover-color: #{desaturate($google-color, 10)};
  --c-facebook-auth-color: #{$facebook-color};
  --c-facebook-auth-hover-color: #{desaturate($facebook-color, 10)};

  // Toasts
  --c-toast-success-color: var(--c-success-color);
  --c-toast-error-color: var(--c-error-color);
  --c-main-decimal-color: 0, 176, 37;

  // Fonts
  --c-font-primary: "Open Sans";
  --c-font-secondary: "Comfortaa";
  --c-font-display: "Rubik";
}
