@use 'sass:math';

$icon-size: 36px;

.navigation-buttons {
  $this: &;

  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  &__label {
    font-size: 16px;
    text-align: center;

    &_next {
      flex-grow: 1;
      padding: 0 0 0 math.div($icon-size, 2);
    }

    &_back {
      flex-grow: 1;
      padding: 0 math.div($icon-size, 2) 0 0;
    }

    &:empty {
      display: none;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $icon-size;
    height: $icon-size;
  }

  &__button {
    flex: 1 1 0;
    min-width: initial;
    padding: 0;

    &_next {
      margin: 0 0 0 5px;
    }

    &_last {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: center;
    }

    &_back {
      margin: 0 5px 0 0;
    }
  }

  &__review-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;

    & button {
      max-width: none;
    }
  }
}
