.social-auth {
  &_type_facebook {
    background-color: var(--c-facebook-auth-color);

    &:hover {
      background-color: var(--c-facebook-auth-hover-color);
    }
  }

  &_type_google {
    background-color: var(--c-google-auth-color);

    &:hover {
      background-color: var(--c-google-auth-hover-color);
    }
  }
}
