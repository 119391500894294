.minimal-ui-buttons {
  &__main-button {
    position: fixed !important;
    bottom: 10px;
    left: 50%;
    z-index: 10;
    display: flex;
    font-size: 16px;
    transform: translateX(-50%);
  }

  &__main-caption {
    flex-grow: 1;
    flex-shrink: 0;
  }

  &__icon-button {
    position: fixed !important;
    top: 15px;
    right: 15px;
    z-index: 10;
  }
}
