.progress-bar {
  $this: &;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 4px;
  pointer-events: none;

  &:hover {
    & #{$this}__tab {
      color: var(--c-main-color);
      background-color: var(--c-main-color);
    }
  }

  @media (min-width: 640px) {
    margin: 10px 0 20px;
  }

  &__tab {
    position: relative;
    display: flex;
    flex-direction: row;
    color: var(--c-secondary-color);
    background-color: var(--c-disabled-color);
    cursor: pointer;
    pointer-events: auto;

    &_disabled {
      pointer-events: none;
    }

    &:hover {
      color: var(--c-main-color);
      background-color: var(--c-main-color);

      & ~  #{$this}__tab {
        color: var(--c-secondary-color);
        background-color: var(--c-disabled-color);
      }
    }

    &_completed {
      color: var(--c-main-color);
      font-weight: 700;
      background-color: var(--c-main-color);

      &#{$this}__tab_disabled {
        color: var(--c-secondary-color);
        background-color: var(--c-secondary-color);
      }
    }
  }

  &__index {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: block;
    padding: 10px 0 0;
    color: inherit;
    font-weight: inherit;
    font-size: 12px;
    text-align: center;
  }
}
