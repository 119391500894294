.footer {
  padding: 20px 0;
  color: var(--c-secondary-color);
  font-size: 12px;
  text-align: center;

  &__links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 0 10px;
  }

  &__link {
    position: relative;
    margin: 0 10px;
    color: inherit;
    text-decoration: underline;
    background: none;
    border: none;

    &_developer {
      &::after {
        display: none;
      }
    }

    &:not(:last-child) {
      &::after {
        position: absolute;
        top: 50%;
        right: -11px;
        display: block;
        color: var(--c-secondary-color);
        transform: translateY(-50%);
        content: '•';
      }
    }
  }
}
