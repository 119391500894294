@import '~balloon-css/src/balloon';
@import '_variables';

html,
body,
#root,
.app {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background: var(--c-background-color);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  user-select: none;
}

body {
  position: relative;
  min-width: 320px;
  color: var(--c-text-color);
  font-size: 16px;
  font-family: var(--c-font-primary), sans-serif;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

textarea {
  resize: none;
}

li {
  list-style: none;
}

input,
button,
textarea,
select,
optgroup,
option {
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
}

input,
button,
textarea,
select {
  *font-size: 100%;
}

input,
textarea {
  user-select: initial;
}

button {
  box-shadow: none;
  cursor: pointer;

  &:disabled {
    pointer-events: none;
  }
}

strong {
  font-weight: 700;
}

:focus {
  outline: none;
}

// balloon css
a[aria-label][data-balloon-pos] {
  overflow: visible;
}

[aria-label][data-balloon-pos] {
  &::after,
  &::before {
    transition-delay: 300ms;
  }
}

@media (hover: none) {
  [aria-label][data-balloon-pos]:not([data-balloon-visible]):hover {
    &::after,
    &::before {
      opacity: 0 !important;
    }
  }
}

input[type='range'] {
  touch-action: pan-y;
}
