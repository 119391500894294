.slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 5px 0 0;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 10px;
  }

  &__value {
    font-weight: 700;
  }

  &__range {
    width: 100%;
    height: 34px;
    cursor: pointer;
    -webkit-appearance: none;

    &:focus {
      outline: none;
    }
  }
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  background: var(--c-main-color);
  border-radius: 2px;
}

input[type='range']::-webkit-slider-thumb {
  width: 40px;
  height: 40px;
  margin-top: -18px;
  background: #fff;
  border: 4px solid var(--c-main-color);
  border-radius: 50%;
  cursor: pointer;
  -webkit-appearance: none;
}

input[type='range']:focus::-webkit-slider-runnable-track {
  background: var(--c-main-color);
}

input[type='range']::-moz-range-track {
  width: 100%;
  height: 4px;
  background: var(--c-main-color);
  border-radius: 2px;
}

input[type='range']::-moz-range-thumb {
  width: 32px;
  height: 32px;
  background: #fff;
  border: 4px solid var(--c-main-color);
  border-radius: 50%;
}

input[type='range']::-ms-track {
  width: 100%;
  height: 4px;
  color: transparent;
  background: transparent;
  border-color: transparent;
}

input[type='range']::-ms-fill-lower {
  background: var(--c-main-color);
  border-radius: 2px;
}

input[type='range']::-ms-fill-upper {
  background: var(--c-main-color);
  border-radius: 2px;
}

input[type='range']::-ms-thumb {
  width: 25px;
  height: 25px;
  margin-top: 1px;
  background: #fff;
  border: 3px solid var(--c-main-color);
  border-radius: 24px;
}

input[type='range']:focus::-ms-fill-lower {
  background: var(--c-main-color);
}

input[type='range']:focus::-ms-fill-upper {
  background: var(--c-main-color);
}
