.notification {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  background: rgba(255, 255, 255, 0.7);

  &__container {
    position: relative;
    width: calc(100% - 20px);
    max-width: 600px;
    margin: 0 20px;
    padding: 0 10px;
    background: var(--c-background-color);
    border: 4px solid transparent;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    &::before {
      position: absolute;
      top: 0;
      right: 10px;
      left: 10px;
      z-index: 1;
      display: block;
      height: 10px;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
      content: '';
    }

    &::after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      display: block;
      height: 5px;
      background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
      content: '';
    }

    &_type {
      &_success {
        border-color: var(--c-main-color);
      }

      &_warning {
        border-color: var(--c-accent-color);
      }
    }
  }

  &__body {
    position: relative;
    max-height: 45vh;
    padding: 5px 0;
    overflow: auto;
  }

  &__header {
    position: relative;
    font-weight: 700;
    background: transparent;
  }

  &__content {
    padding: 0 0 10px;

    & a {
      text-decoration: underline;
    }
  }

  &__actions {
    position: sticky;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &__button {
    min-width: auto;
    margin: 0 0 0 10px;
  }
}
