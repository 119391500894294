.content {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;

  &_type {
    &_scrollable {
      flex-direction: column;
      align-items: center;
      height: auto;
      min-height: 100%;
    }

    &_configurator {
      flex-direction: column-reverse;
      overflow: hidden;

      @media (min-width: 640px) {
        flex-direction: row;
      }
    }
  }
}
