.panorama {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 75%;

  &:active {
    cursor: grabbing;
  }

  & canvas {
    position: absolute;
    top: 0;
    bottom: 0;
  }

  &_fullscreen {
    padding-top: 125%;

    @media (min-width: 640px) {
      width: 90%;
      padding-top: 100%;
    }

    @media (min-width: 768px) {
      width: 90%;
      padding-top: 75%;
    }

    @media (min-width: 1024px) {
      width: 80%;
      padding-top: 66%;
    }

    @media (min-width: 1200px) {
      width: 70%;
      padding-top: 50%;
    }
  }

  &__orbit {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &-wrapper {
      position: relative;
      z-index: 1000;
      width: 50px;
      height: 50px;
      padding: 10px;
      background-color: rgba(51, 51, 51, 0.5);
      border-radius: 50px;
      backdrop-filter: blur(2px);
    }

    &-icon {
      width: 100%;
      height: 100%;
      color: #fff;
    }

    &-sonar {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 10px solid #fff;
      border-radius: 100%;
      animation: sonar 2s infinite;
    }
  }
}

@keyframes sonar {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }

  30% {
    opacity: 0.4;
  }

  90% {
    transform: scale(2);
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}
