.field {
  &__label {
    width: 100%;
  }

  &__input {
    display: block;
    width: 100%;
    height: 40px;
    margin-top: 10px;
    padding: 0 5px;
    border: 4px solid var(--c-main-color);
    appearance: none;

    &_type_checkbox {
      position: relative;
      width: 40px;

      &:checked::after {
        position: absolute;
        content: 'a';
      }
    }



    &_type {
      &_textarea {
        height: 80px;
        padding: 5px;
      }

      &_select {
        position: relative;
        padding: 0;
        border: none;
        appearance: initial;
      }
    }

    &_auto-height {
      height: 42px; /* Start height */
      overflow: hidden;
    }

    &_bold {
      font-weight: 700;
    }
  }

  &__indicator {
    color: var(--c-accent-color);
  }

  &__description {
    margin: 5px 0;
    color: var(--c-secondary-color);
    font-size: 12px;

    &:empty {
      display: none;
    }
  }

  &__feedback {
    display: flex;
    align-items: center;
    margin-top: 5px;
    color: var(--c-error-color);
    font-size: 12px;
  }

  &__select-option {
    font-weight: 700;

    &_active {
      color: var(--c-main-color);
    }
  }
}
