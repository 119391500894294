@use 'sass:math';

$icon-size: 28px;
$border-size: 4px;

.switch {
  $this: &;

  display: inline-flex;

  & > input {
    position: absolute; /* Take out of flow for Firefox */
    width: 0;
    height: 0;
    visibility: hidden;
  }

  &:hover {
    #{$this}__icon {
      background-color: var(--c-main-a10-color);
    }
  }

  &_disabled {
    cursor: default;
    pointer-events: none;
  }

  &_disabled,
  &_inactive {
    color: var(--c-secondary-color);

    &:hover {
      #{$this}__icon {
        background-color: var(--c-text-a10-color);
      }
    }
  }

  &__label {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
  }

  &__group-label {
    display: block;
    width: 100%;
  }

  &__caption {
    font-weight: 700;
  }

  &__icon {
    $icon: &;

    position: relative;
    flex: none;
    height: $icon-size;
    margin-right: 10px;
    border: $border-size solid var(--c-main-color);
    transition: background-color 300ms;

    &_hidden {
      display: none;
    }

    &_type {
      &_radio {
        width: $icon-size;
        border-radius: 50%;

        &::before {
          $gap: math.div($icon-size - ($border-size * 2) - math.div($icon-size, 2), 2);

          position: absolute;
          top: $gap;
          left: $gap;
          display: block;
          box-sizing: border-box;
          width: math.div($icon-size, 2);
          height: math.div($icon-size, 2);
          border-color: inherit;
          border-style: solid;
          border-width: 7px;
          border-radius: 50%;
          opacity: 0;
          transition: opacity 300ms ease-in;
          content: '';
        }
      }

      &_checkbox {
        width: $icon-size;

        &::before {
          position: absolute;
          top: 2px;
          left: 6px;
          display: block;
          width: 5px;
          height: 11px;
          border-color: inherit;
          border-style: solid;
          border-width: 0 3px 3px 0;
          transform: rotate(45deg);
          opacity: 0;
          transition: opacity 300ms ease-in;
          content: '';
        }
      }

      &_toggle {
        $gap: 2px;
        $width: 50px;

        width: $width;
        border-radius: math.div($icon-size, 2);

        &::before {
          position: absolute;
          top: $gap;
          left: $gap;
          display: block;
          box-sizing: border-box;
          width: math.div($icon-size, 2) + $gap;
          height: math.div($icon-size, 2) + $gap;
          border-color: inherit;
          border-style: solid;
          border-width: 2px;
          border-radius: 50%;
          transition: transform 500ms ease, background-color 300ms ease;
          content: '';
        }

        &#{$icon}_checked {
          &::before {
            background-color: var(--c-main-color);
            transform: translateX(math.div($width, 2) - $gap * 2);
          }

          &#{$icon}_disabled,
          &#{$icon}_inactive {
            &::before {
              background-color: var(--c-disabled-color);
            }
          }
        }
      }
    }

    &_checked {
      &::before {
        opacity: 1;
      }
    }

    &_disabled,
    &_inactive {
      border-color: var(--c-disabled-color);
    }
  }
}
