.menu-button {
  $this: &;

  &_type {
    &_header {
      position: absolute !important;
      top: 10px;
      left: -10px;
      border: none;
    }

    &_configurator {
      position: fixed !important;
      top: 10px;
      left: 10px;
      z-index: 220;
    }
  }

  &__indicator {
    position: absolute;
    top: 0;
    right: 0;
  }
}
