@import 'mixins';

.option {
  $this: &;

  &__label {
    display: block;
    font-weight: 700;
    user-select: text;

    &_type {
      &_column,
      &_twoColumn,
      &_threeColumn {
        text-align: center;
      }

      &_dropdown,
      &_dropdownPreview {
        &#{$this}__label_active {
          color: var(--c-main-color);
        }
      }
    }
  }

  &__material {
    width: 100%;
    background: var(--c-background-color);

    &_disabled,
    &_inactive {
      opacity: 0.2;
    }
  }

  &__dropdown-image-wrapper {
    position: relative;
    flex-shrink: 0;
    width: 70px;
    height: 70px;
    margin: 0 10px 0 0;

    &_selected {
      border: 4px solid var(--c-main-color);
    }
  }

  &__image-wrapper {
    position: relative;
    width: calc(100% - 10px);
    height: 0;
    margin: 4px;
    padding: calc(50% - 5px) 0;

    /* A little bit different from mixin */
    background: var(--c-disabled-color);

    & .option__material {
      height: 100%;
      object-fit: contain;
    }
  }

  &__dropdown-image-wrapper,
  &__image-wrapper {
    background: var(--c-disabled-color);

    & .option__material {
      position: absolute;
      top: 0;
    }

    &:empty {
      display: none;
    }
  }

  &_type {
    &_controlImages,
    &_controlMaterials,
    &_bulkMaterials {
      margin: 5px 0;
      border: 4px solid transparent;

      &#{$this}_checked {
        border-color: var(--c-main-color);
      }

      & > .switch__label {
        @include image-placeholder;
      }
    }

    &_controlImages {
      width: 50%;
    }

    &_controlMaterials {
      width: 33.33%;
    }

    &_bulkMaterials {
      width: 25%;
    }

    &_toggle {
      display: flex;
      width: 100%;
    }

    &_option {
      display: flex;
      width: 100%;
      min-height: 33px;
      margin: 10px 0;

      &:first-child {
        margin: 0 0 10px;
      }

      &:last-child {
        margin: 10px 0 0;
      }
    }

    &_column,
    &_twoColumn,
    &_threeColumn {
      display: flex;
      margin: -2px;
      padding: 5px 0;
      border: 4px solid var(--c-main-color);

      & .switch__label {
        flex-direction: column;
        overflow: hidden;
      }

      .hint {
        text-align: center;
      }

      &#{$this}_checked {
        color: #fff;
        background: var(--c-main-color);

        .price {
          color: #fff;
        }

        &#{$this}_inactive {
          background: var(--c-disabled-color);
        }
      }
    }
  }

  &__caption {
    display: flex;
    flex-grow: 1;
  }

  &__content {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;

    &_type {
      &_controlImages,
      &_controlMaterials,
      &_bulkMaterials {
        min-height: 40px;
      }

      &_column,
      &_twoColumn,
      &_threeColumn {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 10px;
      }

      &_dropdownPreview {
        flex-direction: column;
        justify-content: flex-start;
      }
    }

    &_inactive {
      color: var(--c-secondary-color);
    }
  }

  &__info,
  &__data {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &_type {
      &_column,
      &_twoColumn,
      &_threeColumn {
        align-items: center !important;
      }
    }
  }

  &__data {
    flex-shrink: 0;
    align-items: flex-end;

    &_type {
      &_dropdownPreview {
        align-items: flex-start;
      }
    }
  }
}
