.button-group {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  pointer-events: none;

  & .button-v2 {
    min-width: 0;
    padding: 0 15px;
    pointer-events: auto;

    &:first-child {
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
    }

    &:not(:first-child) {
      border-left: none;
    }

    &:last-child {
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
    }
  }
}

.transform-controls {
  position: absolute;
  bottom: 10px;
  justify-content: center;
  width: 100%;
  visibility: visible;
  opacity: 1;
  transition: opacity 200ms, visibility 200ms;

  &_hidden {
    visibility: hidden;
    opacity: 0;
  }

  &_raised {
    bottom: 70px;
  }
}
