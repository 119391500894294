.progress {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 11;
  width: 60px;
  height: 60px;
  color: var(--c-main-color);
  text-align: center;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  transform: translate(-50%, -50%);

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
