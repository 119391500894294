.pdf-menu {
  &__link {
    color: var(--c-main-color);
    text-decoration: underline;
  }

  &__content {
    margin: 0 0 20px;

    & > p {
      margin: 10px 0;
    }
  }
}
