.toasts {
  position: fixed;
  top: 100px;
  left: 50%;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  max-height: calc(100% - 100px);
  padding: 0 10px;
  transform: translateX(-50%);

  @media (min-width: 640px) {
    max-width: 600px;
  }

  &__toast {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    min-height: 50px;
    margin-bottom: 4px;
    overflow: hidden;
    background: var(--c-background-color);
    border: 4px solid transparent;
    border-radius: 50px;

    &_type {
      &_success {
        color: var(--c-toast-success-color);
        border-color: var(--c-toast-success-color);
      }

      &_error {
        color: var(--c-toast-error-color);
        border-color: var(--c-toast-error-color);
      }
    }

    &-enter {
      transform: translateY(20px);
      opacity: 0;

      &-active {
        transform: translateY(0);
        opacity: 1;
        transition: opacity 250ms ease-in, transform 200ms ease-in;
      }
    }

    &-exit {
      opacity: 1;

      &-active {
        opacity: 0;
        transition: opacity 500ms ease-in;
      }
    }
  }

  &__message {
    flex-grow: 1;
    flex-shrink: 1;
    align-self: center;
    padding: 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    text-align: left;
  }

  &__close {
    position: relative;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 60px;
    color: var(--c-text-color);
    background: none;
    border: none;
    cursor: pointer;

    &:hover {
      background: var(--c-text-a10-color);
    }

    &::after {
      position: absolute;
      top: 5px;
      bottom: 5px;
      left: 0;
      display: block;
      width: 1px;
      background: var(--c-disabled-color);
      content: '';
    }
  }

  &__icon {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 60px;
  }

  &__reload {
    padding: 0;
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
  }
}
