.markdown-content {
  padding: 0 0 20px;

  & * {
    user-select: text;
  }

  &:empty {
    display: none;
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  code,
  kbd,
  pre,
  samp {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  }

  audio,
  canvas,
  embed,
  iframe,
  img,
  object,
  svg,
  video {
    display: block;
    vertical-align: middle;
  }

  img,
  video {
    max-width: 100%;
    height: auto;
  }

  a {
    color: var(--c-text-color);
    text-decoration: underline;
  }

  strong {
    color: var(--c-text-color);
    font-weight: 700;
  }

  li {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  ol {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
    counter-reset: list-counter;
  }

  ol > li {
    position: relative;
    padding-left: 1.75em;
    counter-increment: list-counter;
  }

  ol > li::before {
    position: absolute;
    left: 0;
    color: var(--c-secondary-color);
    font-weight: 400;
    content: counter(list-counter) '.';
  }

  ul > li {
    position: relative;
    padding-left: 1.75em;
  }

  ul > li::before {
    position: absolute;
    top: 0.6875em;
    left: 0.25em;
    width: 0.375em;
    height: 0.375em;
    background-color: var(--c-disabled-color);
    border-radius: 50%;
    content: '';
  }

  hr {
    height: 1px;
    margin-top: 3em;
    margin-bottom: 3em;
    color: var(--c-disabled-color);
    background-color: var(--c-disabled-color);
    border: none;
  }

  blockquote {
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-left: 1em;
    color: var(--c-text-color);
    font-weight: 500;
    font-style: italic;
    border-left-color: var(--c-disabled-color);
    border-left-width: 0.25rem;
    quotes: '\201C''\201D''\2018''\2019';
  }

  blockquote p:first-of-type::before {
    content: open-quote;
  }

  blockquote p:last-of-type::after {
    content: close-quote;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 0.8888889em;
    color: var(--c-text-color);
    font-weight: 800;
    font-size: 2.25em;
    line-height: 1.1111111;
  }

  h2 {
    margin-top: 2em;
    margin-bottom: 1em;
    color: var(--c-text-color);
    font-weight: 700;
    font-size: 1.5em;
    line-height: 1.3333333;
  }

  h3 {
    margin-top: 1.6em;
    margin-bottom: 0.6em;
    font-size: 1.25em;
    line-height: 1.6;
  }

  h3,
  h4 {
    color: var(--c-text-color);
    font-weight: 700;
  }

  h4 {
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    line-height: 1.5;
  }

  figure figcaption {
    margin-top: 0.8571429em;
    color: var(--c-secondary-color);
    font-size: 0.875em;
    line-height: 1.4285714;
  }

  code {
    color: var(--c-text-color);
    font-weight: 700;
    font-size: 0.875em;
  }

  code::after,
  code::before {
    content: '`';
  }

  pre {
    margin-top: 1.7142857em;
    margin-bottom: 1.7142857em;
    padding: 0.8571429em 1.1428571em;
    overflow-x: auto;
    color: var(--c-disabled-color);
    font-size: 0.875em;
    line-height: 1.7142857;
    background-color: var(--c-text-color);
    border-radius: 0.375rem;
  }

  pre code {
    padding: 0;
    color: inherit;
    font-weight: 400;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
    background-color: transparent;
    border-width: 0;
    border-radius: 0;
  }

  pre code::after,
  pre code::before {
    content: '';
  }

  table {
    width: 100%;
    margin-top: 2em;
    margin-bottom: 2em;
    font-size: 0.875em;
    line-height: 1.7142857;
    text-align: left;
    table-layout: auto;
  }

  thead {
    color: var(--c-text-color);
    font-weight: 700;
    border-bottom-color: var(--c-disabled-color);
    border-bottom-width: 1px;
  }

  thead th {
    padding-right: 0.5714286em;
    padding-bottom: 0.5714286em;
    padding-left: 0.5714286em;
    vertical-align: bottom;
  }

  tbody tr {
    border-bottom-color: var(--c-disabled-color);
    border-bottom-width: 1px;
  }

  tbody tr:last-child {
    border-bottom-width: 0;
  }

  tbody td {
    padding: 0.5714286em;
    vertical-align: top;
  }

  p {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }

  figure,
  img,
  video {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  h2 code {
    font-size: 0.875em;
  }

  h3 code {
    font-size: 0.9em;
  }

  ul {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }

  > ul > li p {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }

  > ul > li > :first-child {
    margin-top: 1.25em;
  }

  > ul > li > :last-child {
    margin-bottom: 1.25em;
  }

  > ol > li > :first-child {
    margin-top: 1.25em;
  }

  > ol > li > :last-child {
    margin-bottom: 1.25em;
  }

  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }

  h2 + *,
  h3 + *,
  h4 + *,
  hr + * {
    margin-top: 0;
  }

  thead th:first-child {
    padding-left: 0;
  }

  thead th:last-child {
    padding-right: 0;
  }

  tbody td:first-child {
    padding-left: 0;
  }

  tbody td:last-child {
    padding-right: 0;
  }
}
