.controls-panel-toggle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: visible;

  &__icon {
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 50%;

    &_desktop {
      display: none;

      @media (min-width: 640px) {
        display: flex;
      }
    }

    &_mobile {
      display: flex;

      @media (min-width: 640px) {
        display: none;
      }
    }
  }
}
