.index {
  position: relative;
  box-sizing: border-box;
  min-height: 100vh;
  padding-bottom: 60px;
}

.index__header {
  height: 60px;
  color: white;
  font-size: 18px;
  line-height: 60px;
  text-align: center;
  background: #00ce60;
  transition: 0.2s all ease;
}

.index__logo {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  vertical-align: middle;
}

.index__footer {
  width: 100%;
  margin-bottom: 20px;
  color: #202020;
  font-size: 10px;
  text-align: center;
}

.index__footer__link {
  display: inline-block;
  margin: 0 5px;
  color: #202020;
  font-size: 12px;
}

.index__footer__link:hover {
  text-decoration: underline;
}

.index__cta {
  margin-top: 20px;
  font-size: 20px;
  text-align: left;
  text-align: center;
  opacity: 0.8;
}

.index__projects {
  width: 100%;
  max-width: 400px;
  margin: 20px auto;
  text-align: center;
  list-style-type: none;
}

.index__project {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 200px;
  overflow: hidden;
  border: 5px white solid;
  cursor: pointer;
  transition: 0.2s all ease;
}

.index__project__name {
  position: absolute;
  bottom: 20px;
  width: 100%;
  color: white;
  font-size: 30px;
  text-align: center;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  transition: 0.2s bottom ease;
}

.index__project:hover .index__project__name {
  bottom: 60px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 24px;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.index__project__image {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 100%;
  transform: translate(-50%, -50%);
  transition: 0.2s all ease;
}

.index__project__CTA {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 40px;
  color: white;
  font-size: 30px;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  background-color: transparent;
  transform: scaleY(0);
  transition: 0.2s all ease;
}

.index__project:hover .index__project__CTA {
  background-color: rgba(255, 255, 255, 0.4);
  transform: scaleY(1);
}

@media (min-width: 768px) {
  .index__header {
    height: 80px;
    font-size: 30px;
    line-height: 80px;
  }

  .index__logo {
    display: inline-block;
    width: 50px;
    height: 50px;
    margin-right: 10px;
    vertical-align: middle;
  }

  .index__cta {
    margin-left: calc(50vw - 384px);
    font-size: 30px;
    text-align: left;
  }

  .index__projects {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 768px;
  }

  .index__project {
    width: 50%;
  }

  .index__footer {
    position: absolute;
    bottom: 0;
  }
}
