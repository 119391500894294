:root {
  --c-saved-design-frame-color: var(--c-disabled-color);
  --c-saved-design-active-color: var(--c-main-color);
}

.saved-design {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  margin: 10px 0;
  padding: 10px;
  color: var(--c-secondary-color);
  border: 2px solid var(--c-saved-design-frame-color);

  &_active {
    border-color: var(--c-saved-design-active-color);
  }

  &_deprecated {
    color: var(--c-disabled-color);
  }

  &__header {
    color: var(--c-text-color);
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;

    &_active {
      color: var(--c-saved-design-active-color);
    }

    &_deprecated {
      color: var(--c-disabled-color);
    }
  }

  &__paragraph {
    font-size: 12px;
    line-height: 120%;

    & .icon {
      position: relative;
      top: -2px;
      margin: 0 -3px;
      color: var(--c-saved-design-active-color);
      vertical-align: middle;
    }

    &_deprecation {
      color: var(--c-secondary-color);

      & .icon {
        color: var(--c-error-color);
      }
    }
  }

  &__project-name {
    font-weight: 700;
  }

  &__instance-info {
    flex-grow: 1;

    &_deprecated {
      color: var(--c-disabled-color);
    }
  }

  &__delete-button {
    flex-grow: 0;
    flex-shrink: 0;
    color: var(--c-secondary-color);

    &:hover {
      color: var(--c-secondary-hover-color);
    }
  }

  &__create-new {
    width: calc(100% - 28px);
    margin: 14px;
  }
}
