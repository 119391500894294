@import 'mixins';

.control {
  $this: &;

  padding: 18px 0;

  &:not(:first-child) {
    border-top: 1px solid var(--c-disabled-color);
  }

  &_collapsable {
    border-top: none !important;

    + #{$this} {
      border-top: none !important;
    }
  }

  &__body {
    position: relative;
    border: 2px solid transparent;

    &_collapsable {
      padding: 10px;
      border-color: var(--c-disabled-color);
    }

    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: -26px;
      display: block;
      width: 8px;
      height: 100%;
      background: var(--c-main-color);
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      box-shadow: 0 0 0 0 rgba(var(--c-main-decimal-color), 0.7);
      transform: translateX(-4px);
      content: '';
    }

    &_selected {
      &::before {
        transform: translateX(0);
        animation: bounce 2s;
      }
    }

    &_mobile {
      &::before {
        display: none !important;
      }
    }
  }

  &__header-wrapper {
    width: 100%;
    margin: 0 0 10px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 150%;
  }

  &:only-child {
    padding: 0 !important;
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  &__collapse-icon {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-left: 10px;
    color: var(--c-main-color);
    border: 2px solid var(--c-main-color);
    border-radius: 50%;
  }

  &__description {
    color: var(--c-secondary-color);
    font-size: 12px;
    line-height: 120%;
  }

  &__options {
    display: grid;

    &_type {
      &_twoColumn {
        grid-template-columns: repeat(2, 1fr);
      }

      &_threeColumn {
        grid-template-columns: repeat(3, 1fr);
      }

      &_controlImages,
      &_controlMaterials,
      &_bulkMaterials {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    padding: 10px 0 0;

    &:empty {
      display: none;
    }
  }

  &__preview {
    width: 33.33%;
  }

  &__preview-image {
    @include image-placeholder;

    display: flex;
    align-items: center;
  }

  &__broken-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    border: 2px solid var(--c-disabled-color);
  }

  &__broken-header {
    margin: 0 0 10px;
    font-weight: 700;
    text-align: center;
  }

  &__broken-message {
    text-align: center;
  }

  &__fix-button {
    min-width: initial !important;
    margin: 20px 0 0;
  }

  &__title {
    flex-grow: 1;
    line-height: 150%;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
    user-select: text;

    &_fixed {
      color: var(--c-disabled-color);
    }
  }

  &__child-tab-button,
  &__instance-loader-button {
    display: flex;
    height: 60px;
    margin: 10px 0 0;
    border-radius: 10px;

    &-name {
      flex-grow: 1;
      word-break: break-word;
    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    margin: 12px -4px;
  }

  &__tag {
    margin: 4px;
    padding: 0 10px !important;

    &_disabled {
      pointer-events: none;
    }

    &_marked {
      position: relative;
      padding: 0 10px 0 15px !important;

      &::before {
        position: absolute;
        top: 50%;
        left: 7px;
        transform: translateY(-50%);
        content: '\2022';
      }
    }
  }

  &__empty-list-message {
    color: var(--c-secondary-color);
    font-size: 12px;
  }
}

@keyframes bounce {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--c-main-decimal-color), 0.7);
    transform: translateX(-4px);
  }

  15% {
    transform: translateX(4px);
  }

  30% {
    transform: translateX(0);
  }

  60% {
    box-shadow: 0 0 0 10px rgba(var(--c-main-decimal-color), 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(var(--c-main-decimal-color), 0);
    transform: translateX(0);
  }
}
