.scene-button {
  &_type {
    &_close {
      left: 0;
    }

    &_move {
      top: -75px;
      left: -50px;
    }

    &_delete {
      top: -75px;
      left: 50px;
    }

    &_copy {
      top: -75px;
    }

    &_comment {
      width: 40px;
      min-width: 0;
      height: 40px;
      margin-top: -30px;
      padding: 0;
      overflow: visible;
      font-weight: bold;
      font-size: 20px;
      line-height: 20px;
      background: none;
      transition: ease opacity 0.5s 1.5s;

      &-exit-active {
        opacity: 0;
      }

      &-enter-done {
        opacity: 1;
      }
    }
  }

  &__comment-shape {
    position: absolute;
    z-index: -1;
    width: 40px;
    height: 40px;
    background-color: var(--c-background-color);
    border: 5px solid var(--c-accent-color);
    border-radius: 50%;
    border-bottom-right-radius: 0;
    outline: 3px solid var(--c-background-color);
    transform: rotate(45deg);

    &_disabled {
      border-color: var(--c-disabled-color);
    }
  }
}
