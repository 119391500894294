.viewer {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: auto;

  &_embedded {
    height: 100%;
  }
}

.render-target {
  position: relative;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &_embedded {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }

  &_pointer {
    cursor: pointer;
  }
}

.render-canvas {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  transform: translate(-50%, -50%);
  touch-action: none;
}

.interactive-controls {
  position: absolute;
}
