.location-marker {
  display: flex;
  align-items: center;
  justify-content: center;

  &__btn {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-color: var(--c-disabled-color);
    border-style: dashed;

    /* After itself */
    & + & {
      margin-left: 10px;
    }

    &_type {
      &_attach,
      &_placeholder,
      &_replace {
        & .icon {
          color: var(--c-accent-color);
        }
      }

      &_placeholder,
      &_placeholder:focus {
        background: var(--c-background-color);
        border-color: var(--c-accent-color);
        outline: 2px solid var(--c-accent-color);
        outline-offset: 4px;
      }

      &_remove .icon {
        color: var(--c-error-color);
      }
    }
  }
}

.menu__section_type_comments-footer {
  position: sticky;
  bottom: 0;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: auto;
  padding: 5px;
  background-color: var(--c-background-color);
}

.comment-menu {
  &__footer {
    display: flex;
    gap: 5px;
    width: 100%;
  }

  &__footer-btn {
    font-size: 16px;
  }

  &__menu {
    &_half-height {
      max-height: 50%;

      @media (min-width: 640px) {
        max-height: 100%;
      }
    }
  }
}
