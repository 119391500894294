.tab-navigation {
  &__button {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    border-radius: 20px;

    &::after {
      position: absolute;
      top: 50%;
      right: 12px;
      display: block;
      transform: translateY(-50%);
      content: '\25BE';
      pointer-events: none;
    }

    &_disabled {
      pointer-events: none;

      &::after {
        color: var(--c-disabled-color);
      }

      & > * {
        border-color: var(--c-disabled-color) !important;
      }
    }

    &_child {
      &::after {
        right: 5px;
        pointer-events: none;
      }
    }
  }

  &__current {
    min-width: initial;
    font-weight: 700;

    &_mode {
      &_default {
        flex-grow: 1;
        padding: 0 30px 0 5px;
        font-size: 12px;
        border-left: none;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;

        &:only-child {
          padding: 0 30px 0 20px;
          border-left: 2px solid var(--c-text-color);
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
        }
      }

      &_child {
        min-height: initial;
        padding: 5px 20px 5px 5px;
        font-size: 16px;
        border: none;

        &:hover {
          background: none;
        }
      }
    }
  }

  &__group {
    position: sticky;
    top: 0;
    padding: 8px 16px;
    background: linear-gradient(180deg, #fff 0%, #fff 70%, rgba(255, 255, 255, 0) 100%);

    &::before {
      position: absolute;
      top: 50%;
      right: 16px;
      left: 16px;
      height: 1px;
      border-top: 1px solid var(--c-disabled-color);
      content: '';
    }
  }

  &__group-name {
    position: relative;
    display: inline-block;
    padding: 0 16px 0 0;
    color: var(--c-secondary-color);
    font-size: 12px;
    background: var(--c-background-color);
  }

  &__item {
    display: block;
    width: 100%;
    padding: 8px 36px;
    color: var(--c-text-color);
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    background: var(--c-background-color);
    border: none;

    &_active {
      display: flex;
      align-items: center;
      padding-left: 12px;
      color: var(--c-main-color);
    }

    &_empty {
      color: var(--c-disabled-color);
      pointer-events: none;
    }

    &:hover {
      background: var(--c-text-a10-color);
    }
  }

  &__list {
    flex-grow: 1;
    padding: 0 0 16px;
    overflow: auto;
    overscroll-behavior: contain;
  }

  &__backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 300;
    visibility: hidden;

    &_active {
      background: rgba(255, 255, 255, 0.7);
      visibility: visible;
    }
  }

  &__content {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 300;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 320px;
    max-height: calc(100% - 20px);
    overflow: hidden;
    background: var(--c-background-color);
    border: 4px solid var(--c-disabled-color);
    transform: translate(-50%, -50%);

    @media (min-width: 640px) {
      top: 10px;
      left: 10px;
      max-width: 340px;
      transform: translate(0, 0);
    }

    &-enter {
      opacity: 0;

      &-active {
        opacity: 1;
        transition: opacity 200ms;
      }
    }

    &-exit {
      opacity: 1;

      &-active {
        opacity: 0;
        transition: opacity 200ms;
      }
    }

    &::after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      height: 32px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
      content: '';
      pointer-events: none;
    }
  }

  &__header {
    display: block;
    padding: 16px;
    color: var(--c-secondary-color);
  }
}
