.preferences-table {
  &__tab {
    position: relative;

    &-icon {
      display: flex;
      transition: transform 0.3s;

      &_hidden {
        color: var(--c-main-color);
        transform: rotate(-90deg);
      }
    }

    &-name {
      position: relative;
      display: inline-flex;
      align-items: center;
      padding: 0 16px 0 0;
      color: var(--c-secondary-color);
      font-size: 12px;
      line-height: 30px;
      background: var(--c-background-color);
      border: none;
    }

    &::before {
      position: absolute;
      top: 16px;
      right: 0;
      left: 16px;
      height: 1px;
      border-top: 1px solid var(--c-disabled-color);
      content: '';
    }
  }

  &__toggle-child {
    align-self: center;
  }

  &__name {
    flex-basis: 50%;
    flex-grow: 0.5;
    flex-shrink: 1;
    padding-top: 4px;
  }

  &__controls {
    padding: 0 0 0 8px;
    opacity: 1;
    transition: opacity 0.3s, max-height 0.3s;

    &_hidden {
      overflow: hidden;
      opacity: 0;
    }
  }

  &__child-instance {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid var(--c-disabled-color);
  }

  &__wrapper {
    display: flex;
    flex-direction: column;

    &_instance-loader {
      padding: 10px 20px;
      border: 1px solid var(--c-disabled-color);
    }
  }

  &__control {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    min-height: 40px;
    margin: 10px 0;
    font-weight: 700;
    font-size: 16px;

    &_price {
      align-items: flex-start;
    }

    &_child {
      position: relative;
      margin: 10px 0 10px 40px;

      &::before {
        position: absolute;
        top: -10px;
        bottom: 0;
        left: -20px;
        display: block;
        width: 1px;
        background: var(--c-disabled-color);
        content: '';
      }
    }
  }

  &__value {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__result {
    padding: 0 5px 0 10px;
    text-align: right;
  }

  &__option {
    display: block;
    color: var(--c-main-color);

    &_locked {
      color: var(--c-secondary-color);
    }

    &_unavailable,
    &_forcereselect {
      color: var(--c-error-color);
    }
  }

  &__option-label {
    display: block;
    color: var(--c-secondary-color);
  }

  &__price {
    margin-left: 10px;
    color: var(--c-secondary-color);
    font-weight: 400;
    font-size: 12px;
  }

  &__link {
    min-width: initial;
    min-height: 0;
    margin: 0 0 0 10px;
    padding: 4px 4px 4px 0;
    overflow: visible;
    border: none;
    border-radius: 10px;

    &_locked {
      &:hover {
        background-color: var(--c-secondary-hover-color);
      }
    }
  }

  &__status-label {
    display: block;
    color: var(--c-error-color);
    font-weight: 400;
    text-transform: uppercase;
  }

  &__icon {
    vertical-align: middle;

    &_locked {
      color: var(--c-secondary-color);
    }

    &_unavailable,
    &_forcereselect {
      color: var(--c-error-color);
    }
  }
}
