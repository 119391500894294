.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: var(--c-background-color);

  &__text {
    margin: 16px 0 0;
    font-size: 22px;
  }

  &__spinner {
    position: relative;
    display: block;
    width: 125px;
    height: 125px;
    border: 4px solid transparent;
    border-top: 4px solid var(--c-spinner-color);
    border-radius: 50%;
    animation: spin 2s linear infinite;

    &::before,
    &::after {
      position: absolute;
      border: 4px solid transparent;
      border-radius: 50%;
      content: '';
    }

    &::before {
      top: 5px;
      right: 5px;
      bottom: 5px;
      left: 5px;
      border-top-color: var(--c-spinner-color);
      opacity: 0.7;
      animation: spin 3.5s linear infinite;
    }

    &::after {
      top: 15px;
      right: 15px;
      bottom: 15px;
      left: 15px;
      border-top-color: var(--c-spinner-color);
      opacity: 0.4;
      animation: spin 1.75s linear infinite;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
