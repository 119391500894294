.service-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &__title {
    margin: 0 0 20px;
    color: var(--c-accent-color);
    font-size: 52px;
  }

  &__content {
    width: 350px;
    text-align: center;
  }

  &__paragraph {
    margin: 0 0 10px;

    &:last-of-type {
      margin: 0 0 20px;
    }
  }
}
