.home-button {
  width: 45px;
  min-width: 45px;
  padding: 0;
  border-right: none;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;

  &_disabled {
    color: var(--c-disabled-color);
    border-color: var(--c-disabled-color) !important;
  }

  &[disabled] {
    border-color: var(--c-text-color);
  }

  &__divider {
    position: absolute;
    top: 5px;
    right: 0;
    bottom: 5px;
    width: 1px;
    background: var(--c-disabled-color);
    content: '';
  }
}
