.page-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0 40px;

  &__media {
    position: relative;
    min-height: 150px;
    margin: 0 -20px 30px;

    @media (min-width: 768px) {
      min-height: 300px;
      margin: 0 0 30px;
    }

    &:empty {
      display: none;
    }
  }

  &__body {
    @media (min-width: 768px) {
      max-width: 624px;
      padding: 0 40px 0 0;
    }
  }

  &__summary {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 0;
    align-items: flex-start;
    justify-content: center;
  }
}
