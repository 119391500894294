@use "sass:math";

$size: 40px;

.zoom-buttons {
  $this: &;

  position: absolute;
  right: 15px;
  bottom: 10px;
  transition: transform 200ms ease-in;

  &_mode {
    &_review {
      bottom: 10px;
    }

    &_controls-hidden {
      bottom: 80px;

      @media (min-width: 640px) {
        bottom: 10px;
      }
    }

    &_controls-visible {
      transform: translateY(70px);

      @media (min-width: 640px) {
        transform: translateY(0);
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $size;
    height: $size;
    overflow: hidden;
    color: var(--c-main-color);
    background: var(--c-background-color);
    border: 2px solid var(--c-main-color);
    border-radius: 50%;
    transition: background-color 300ms;

    &:hover {
      background: var(--c-main-a10-color);
    }

    &:first-child:not(:only-child) {
      border-bottom: none;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:last-child:not(:only-child) {
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &__zoom {
    margin-top: math.div($size, 2);
    opacity: 1;
    transition: opacity 200ms;

    &_controls {
      opacity: 0;

      @media (min-width: 640px) {
        opacity: 1;
      }
    }
  }

  &__zoom,
  &__focus {
    background: var(--c-background-color);
    border-radius: math.div($size, 2);
  }

  &__focus {
    visibility: visible;
    opacity: 1;
    transition: opacity 200ms, visibility 200ms;

    &_hidden {
      visibility: hidden;
      opacity: 0;
    }
  }
}
