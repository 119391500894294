.main-actions {
  &:empty {
    display: none;
  }

  &__action {
    margin: 10px 0 0;

    &_about,
    &_summary {
      font-family: var(--c-font-secondary), cursive;
    }
  }
}
