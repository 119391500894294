.about-page-info {
  max-width: 854px;

  &__subheader {
    margin: 0 0 20px 0;
    font-weight: 700;
  }

  &__actions {
    display: none;
    padding: 60px 0 40px;
    border-top: 1px solid var(--c-disabled-color);
  }
}
