.info-page-gallery {
  &__loader {
    &-exit {
      opacity: 1;

      &-active {
        opacity: 0;
        transition: opacity 400ms;
      }
    }
  }
}
