.comments-panel {
  &__title {
    display: flex;
    justify-content: left;
    font-size: 22px;
  }

  &__subtitle {
    margin-bottom: 40px;
    color: var(--c-secondary-color);
  }

  &__placeholder {
    text-align: center;
  }
}

.comment-box {
  display: flex;
  margin: 10px 0;
  padding: 0;
  text-align: left;

  &__content {
    flex-grow: 1;
    max-width: calc(100% - 44px);
    padding: 10px;
  }

  &__location {
    padding: 10px;
    color: var(--c-disabled-color);

    &_enabled {
      color: var(--c-accent-color);
    }
  }

  &__title {
    width: 100%;
    margin-bottom: 5px;
    overflow: hidden;
    font-weight: bold;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__subtitle {
    margin: 0;
    color: var(--c-secondary-color);
    font-size: 12px;
  }
}
