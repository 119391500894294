.controls-panel-close-children-button {
  &_mode {
    &_icon {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 100;
    }
  }
}
