.gallery {
  position: relative;
  $this: &;

  /* If panorama on mobile view, always show navigation icons */
  &_panorama {
    #{$this}__controls {
      visibility: visible;
      opacity: 1;

      @media (min-width: 640px) {
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  &_navigation {
    &:hover {
      @media (min-width: 640px) {
        #{$this}__controls {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  & .image-gallery-image {
    max-height: none !important;
    object-fit: cover !important;
  }

  & .image-gallery-swipe {
    /* For some reason Firefox does not make the div full width */
    width: 100%;
  }

  &__images {
    background: var(--c-disabled-color);

    .image-gallery-image {
      min-height: 200px;

      @media (min-width: 640px) {
        min-height: 300px;
      }
    }
  }

  &__placeholder {
    & .image-gallery-image {
      width: 100%;
    }
  }

  &__dots {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 20px;
  }

  &__controls {
    position: absolute;
    display: flex;
    width: 120px;
    margin: 0 auto;

    &_type {
      &_gallery {
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        visibility: hidden;
        opacity: 0;
        transition: opacity 400ms, visibility 400ms;
      }

      &_fullscreen {
        bottom: 20px;
      }
    }
  }

  &__button {
    &_type {
      &_prev {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &_next {
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &_fullscreen {
        position: absolute;
        right: 10px;
        bottom: 10px;
      }

      &_close-fullscreen {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 1;
      }
    }
  }

  &__indicator {
    flex-grow: 1;
    line-height: 36px;
    text-align: center;
    background: var(--c-background-color);
    border-top: 2px solid var(--c-main-color);
    border-bottom: 2px solid var(--c-main-color);
  }

  &__dot {
    width: 6px;
    height: 6px;
    margin: 2px;
    background: var(--c-disabled-color);
    border: none;
    border-radius: 50%;
    transition: background-color 250ms;

    &_active {
      background: var(--c-main-color);
    }
  }

  &__fullscreen {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background: rgba(56, 56, 56, 0.7);
    visibility: hidden;

    &_active {
      visibility: visible;
    }
  }

  &__fullscreen-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border: 2px solid var(--c-main-color);
  }

  &__zoom {
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 1;
    display: flex;
    flex-direction: column;
  }

  &__zoom-button {
    &_type {
      &_plus {
        border-bottom: none;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }

      &_minus {
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
}
