.summary-page-info {
  width: 100%;
  margin: 0 auto 60px;

  @media (min-width: 768px) {
    margin: 0 auto 30px;
  }

  &__title {
    color: var(--c-main-color);
    font-weight: 700;
    font-size: 22px;
    font-family: var(--c-font-secondary), cursive;
    text-align: center;
  }

  &__description {
    margin: 20px auto 0;
    text-align: center;

    &:empty {
      display: none;
    }
  }

  &__actions {
    margin: 20px 0;
    padding: 20px 0 0;
    border-top: 1px solid var(--c-disabled-color);
    border-bottom: 1px solid var(--c-disabled-color);

    &-body {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
    }
  }

  &__action {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 50px;
    margin: 0 20px 20px;
    overflow: visible;
    text-align: center;

    &-name {
      display: block;
      margin: 10px 0 0;
      color: var(--c-main-color);
    }
  }
}
