.review-buttons {
  position: fixed;
  bottom: 10px;
  left: 50%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: calc(100% - 130px); // view buttons and zoom buttons
  transform: translateX(-50%);

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__button {
    flex-grow: 1;
    min-width: initial;
    margin: 10px 5px 0;
    padding: 0 26px;
    font-size: 16px;

    @media (min-width: 640px) { flex-grow: 0; }
  }
}
