.summary-info {
  width: 100%;

  &__value {
    color: var(--c-accent-color);
    font-weight: 700;
    text-align: right;
    user-select: text;
  }

  &__description {
    user-select: text;

    &_mode {
      &_overview {
        font-size: 12px;

        @media (min-width: 640px) {
          font-size: 16px;
        }
      }
    }
  }

  &__item {
    $this: &;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    padding: 5px 0;

    &_type {
      &_price {
        height: 50px;
        padding: 10px 0;
        font-weight: 700;
        font-size: 22px;
        border-top: 1px solid var(--c-disabled-color);
        border-bottom: 1px solid var(--c-disabled-color);

        &:only-child {
          border-top: none;
        }
      }
    }

    &_mode {
      &_overview {
        height: 30px;
        padding: 0;
      }
    }
  }

  &:empty {
    display: none;
  }

  &_mode {
    &_overview {
      padding: 5px 0;
      border-bottom: 1px solid var(--c-disabled-color);
    }
  }
}
