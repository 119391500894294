.about-page-video {
  position: relative;
  flex-grow: 1;
  height: 340px;
  pointer-events: none;

  &__frame {
    width: 100%;
    height: 100%;
  }

  &__placeholder {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
