.exit-fullscreen-button {
  z-index: 10;
  display: flex;
  font-size: 16px;

  &__caption {
    flex-grow: 1;
    flex-shrink: 0;
  }

  &_type {
    &_index {
      position: sticky;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
    }

    &_viewer {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
    }

    &_review {
      margin: 0 auto;
    }
  }
}
