.view-buttons {
  $this: &;

  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 50px;
  background: var(--c-background-color);
  border: 2px solid var(--c-main-color);
  border-radius: 25px;
  visibility: visible;
  opacity: 1;
  transition: opacity 200ms, visibility 200ms;

  &_mode {
    &_review {
      bottom: 10px;
    }

    &_controls {
      visibility: hidden;
      opacity: 0;

      @media (min-width: 640px) {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 30px;
    overflow: hidden;
    color: var(--c-main-color);
    white-space: nowrap;
    background: var(--c-background-color);
    border: none;

    &_up {
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
    }

    &_down {
      border-bottom-right-radius: 25px;
      border-bottom-left-radius: 25px;
    }

    & + #{$this}__button {
      border-top: 2px solid var(--c-main-color);
    }

    &_active {
      color: var(--c-background-color);
      background: var(--c-main-color);
    }

    &:hover:not(#{$this}__button_active) {
      background: var(--c-main-a10-color);
    }

    &:disabled {
      & > * {
        opacity: 0.3;
      }
    }
  }
}
