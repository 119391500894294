.unsaved-indicator {
  width: 8px;
  height: 8px;
  background: var(--c-accent-color);
  border-radius: 50%;

  &_border {
    width: 12px;
    height: 12px;
    border: 2px solid var(--c-background-color);
  }
}
