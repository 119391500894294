.controls-panel {
  position: relative;
  z-index: 200;
  flex-shrink: 0;
  width: 100%;
  max-height: 70vh;
  overflow: visible;
  background: var(--c-background-color);
  border-top: 1px solid var(--c-disabled-color);
  opacity: 0;
  transition: margin-bottom 200ms, opacity 250ms;

  @media (min-width: 640px) {
    width: 360px;
    height: 100%;
    max-height: initial;
    margin-left: -360px;
    border-top: none;
    border-right: 1px solid var(--c-disabled-color);
    transition: margin-left 300ms, opacity 350ms;
  }

  &_visible {
    min-height: 170px;
    opacity: 1;

    @media (min-width: 640px) {
      margin-left: 0;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 0 10px;
    background: var(--c-background-color);

    @media (min-width: 640px) {
      padding: 10px 0;
    }
  }

  &__header,
  &__child-header {
    &::before {
      position: absolute;
      top: auto;
      right: 10px;
      bottom: -13px;
      left: 10px;
      display: block;
      height: 20px;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #fff 65%);
      content: '';
      pointer-events: none;

      @media (min-width: 640px) {
        bottom: -24px;
        height: 24px;
      }
    }
  }

  &__child-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;

    &_active {
      margin: 35px 10px 0;
      padding: 0 0 10px;
      border: 2px solid var(--c-text-color);
      border-radius: 10px;

      @media (min-width: 640px) {
        margin: 10px 10px 0;
      }
    }
  }

  &__child-header {
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 10px 10px 0;

    &::before {
      z-index: 10;
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 40px 0 0;
    }
  }

  &__body {
    flex-grow: 1;
    flex-shrink: 1;
    max-height: 40vh;
    padding: 40px 20px 16px;
    overflow: auto;

    @media (min-width: 640px) {
      max-height: initial;
      padding: 20px 20px 16px;
    }

    &_child {
      padding: 20px 20px 16px;
    }
  }

  &__controls {
    position: relative;
  }

  &__toggle {
    transform: translateX(0);
    visibility: visible;
    opacity: 1;
    transition: opacity 100ms linear 200ms, transform 100ms linear 250ms;

    &_hidden {
      visibility: hidden;
      opacity: 0;

      @media (min-width: 640px) {
        transform: translateX(10px);
      }
    }

    &_type {
      &_hide {
        align-self: flex-start;
        padding: 0 0 0 10px;

        @media (min-width: 640px) {
          position: initial;
        }
      }

      &_show {
        position: fixed;
        right: 10px;
        bottom: 10px;
        z-index: 200;
        width: 50px;
        height: 50px;
        border-radius: 50%;

        @media (min-width: 640px) {
          top: 74px;
          left: 8px;
        }
      }
    }
  }

  &__tab-navigation {
    flex-grow: 1;
  }

  &__child-mode-name {
    padding: 0 5px;
    color: var(--c-secondary-color);
    font-size: 12px;
  }

  &__child-tab-name {
    display: block;
    flex-grow: 1;
    padding: 5px;
    font-weight: 700;
    font-size: 16px;
    word-break: break-word;
  }

  &__header {
    position: absolute;
    top: -25px;
    right: 0;
    left: 0;
    z-index: 100;
    padding: 0 10px;

    @media (min-width: 640px) {
      position: relative;
      top: 0;
    }

    &-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-height: 50px;

      @media (min-width: 640px) {
        padding: 0 0 0 60px;
      }
    }

    &_child {
      &::before {
        display: none;
      }
    }
  }

  &__footer {
    position: relative;
    flex-shrink: 0;
    margin: auto 0 0;
    padding: 4px 10px 0;

    &::before {
      position: absolute;
      top: -16px;
      right: 0;
      left: 0;
      display: block;
      height: 20px;
      background: linear-gradient(0deg, #fff 35%, rgba(255, 255, 255, 0) 100%);
      content: '';
      pointer-events: none;
    }
  }
}
