.dropdown-v2 {
  position: relative;
  width: 100%;

  &_active {
    z-index: 10;
  }

  &__button {
    position: relative;
    width: 100%;
    padding: 0 16px 0 8px;
    text-align: left;
    background-color: var(--c-background-color);
    border: 4px solid var(--c-main-color);
    box-shadow: none;
    transition: background-color 300ms;

    &:hover {
      background-color: var(--c-main-a10-color);
    }

    &_disabled {
      color: var(--c-secondary-color);
      border-color: var(--c-disabled-color);
    }

    &::after {
      position: absolute;
      top: 50%;
      right: 8px;
      display: block;
      transform: translateY(-50%);
      content: '\25BE';
    }
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 38px;
    padding: 4px 8px 4px 24px;
    background-color: transparent;
    cursor: pointer;

    &_list {
      background-color: var(--c-background-color);
      transition: background-color 300ms;

      &:hover {
        background-color: var(--c-text-a10-color);
      }
    }

    &_selected {
      pointer-events: none;
    }

    &_active {
      padding: 4px 8px 4px 0;
    }

    &_inactive,
    &_disabled {
      color: var(--c-disabled-color);
    }

    &_disabled {
      pointer-events: none;
    }
  }

  &__active-icon {
    display: inline-flex;
    align-items: center;
    color: var(--c-main-color);
  }

  &__backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    visibility: hidden;

    &_active {
      background: rgba(255, 255, 255, 0.7);
      visibility: visible;
    }
  }

  &__content {
    position: absolute;
    z-index: 220;
    min-width: 100%;
    overflow: auto;
    background: var(--c-background-color);
    border: 4px solid var(--c-disabled-color);
    box-shadow: 5px 5px 10px rgba(51, 51, 51, 0.1);

    &_fixed {
      position: fixed;
      min-width: initial;
    }

    &-enter {
      opacity: 0;

      &-active {
        opacity: 1;
        transition: opacity 300ms;
      }
    }

    &-exit {
      opacity: 1;

      &-active {
        opacity: 0;
        transition: opacity 300ms;
      }

      &-done {
        display: none;
      }
    }
  }

  &__caption {
    font-weight: 700;

    &_active {
      color: var(--c-main-color);
    }
  }
}
