.caveat-item {
  max-width: 360px;
  color: var(--c-secondary-color);
  border-bottom: 1px solid var(--c-disabled-color);

  &__title {
    margin: 0 0 0 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
  }

  &__body {
    overflow: hidden;
    font-size: 12px;
    opacity: 0;
    transition: opacity 450ms, height 450ms;

    &_open {
      opacity: 1;
    }
  }

  &__content {
    padding: 0 0 10px;

    & * {
      user-select: text;
    }
  }

  &__button {
    margin-left: auto;
    padding: 0;
    text-decoration: underline;
    background: transparent;
    border: none;
  }

  &__image {
    flex: none;
    width: 24px;
    height: 24px;

    & img {
      width: 100%;
      height: 100%;
    }
  }
}
