.extra-info-dialog {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 190;
  background: rgba(255, 255, 255, 0.7);

  &::before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: block;
    height: 40px;
    background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
    content: '';

    @media (min-width: 640px) {
      display: none;
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    background: var(--c-background-color);

    @media (min-width: 640px) {
      max-width: 500px;
      border-right: 1px solid var(--c-disabled-color);
    }
  }

  &__body {
    height: 100%;
    padding: 20px 20px 40px;
    overflow: auto;

    @media (min-width: 640px) {
      padding: 20px;
    }
  }

  &__header {
    margin: 0 0 15px;
    padding: 0 44px;
    font-weight: 700;
    font-size: 22px;
    text-align: center;

    @media (min-width: 640px) {
      padding: 0;
    }
  }

  &__info {
    margin: 40px 0 0;
  }

  &__links {
    text-align: center;
  }

  &__images {
    border: 2px solid var(--c-main-color);
  }

  &__actions {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 20px 0 0;
  }

  &__external-link {
    display: inline-flex;
    flex-direction: row;
    color: var(--c-secondary-color);
    text-decoration: underline;
  }

  &__close-icon {
    position: absolute;
    top: 15px;
    right: 10px;
    z-index: 10;
  }

  &__close-btn {
    min-width: initial;
  }

  &-enter,
  &-appear {
    & .extra-info-dialog__content {
      transform: translateX(-20%);
    }

    opacity: 0;

    &-active,
    &-done {
      & .extra-info-dialog__content {
        transform: translateX(0);
        transition: 0.2s transform;
      }

      opacity: 1;
      transition: 0.2s opacity;
    }
  }

  &-exit {
    & .extra-info-dialog__content {
      transform: translateX(0);
    }

    opacity: 1;

    &-active,
    &-done {
      & .extra-info-dialog__content {
        transform: translateX(-20%);
        transition: 0.2s transform;
      }

      opacity: 0;
      transition: 0.2s opacity;
    }
  }
}
